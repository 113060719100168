import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/dist/client/router';
import Script from 'next/script';
import { useEffect } from 'react';
import '../index.scss';
import { pageView } from '../utils/dataLayer';

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setTimeout(() => {
        pageView(window.location.pathname, document.title);
      }, 5);
    };
    router?.events?.on('routeChangeComplete', handleRouteChange);

    return () => {
      router?.events?.off('routeChangeComplete', handleRouteChange);
    };
  }, [router?.events?.on, router?.events?.off]);

  const showMarkerIo = pageProps?.componentProps?.showMarkerIo;

  return (
    <>
      {showMarkerIo && (
        <>
          <Script id="marker-conf">
            {`window.markerConfig = {
    project: '662ba03dd7e3f7fe4b191d8e',
    source: 'snippet'
  };`}
          </Script>
          <Script
            id="marker-script"
            dangerouslySetInnerHTML={{
              __html: `!function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);`,
            }}
          />
        </>
      )}
      <Component {...pageProps} />
    </>
  );
}

export default appWithTranslation(MyApp);
